<template>
  <Layout>
    <div class="main-content message-container">

      <div class="message-box">
        <h1>Usuario no autorizado</h1>
        <i class="fas fa-user-lock fa-7x"></i>
      </div>

    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/empty.vue";

export default {
  components: {
    Layout
  }
};
</script>

<style lang="scss" scoped>
.page-content {
  padding: 0 !important;
}

.development-version-label {
  color: #56BDAD;
}

.message-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .message-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

  }
}
</style>
