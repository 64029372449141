<script>

export default {
  created: () => {
    document.body.removeAttribute("data-layout");
    document.body.setAttribute("data-layout-mode", "dark");
  },
  methods: {},
};
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <div class="main-content">
        <slot/>
      </div>
    </div>
  </div>
</template>
